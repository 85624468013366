export const currencySymbolMap = {
  EUR: '€',
  USD: '$',
  INR: '₹',
};

export function supportsNumberFormat() {
  return !!(typeof Intl !== 'undefined' && Intl && typeof Intl.NumberFormat !== 'undefined');
}

export function formatCurrency(amount, symbol) {
  const currencySymbol = symbol.toUpperCase();
  if ((typeof amount === 'undefined') || !currencySymbol || (amount === null)) {
    return 'None';
  }
  if (typeof amount === 'string' || amount instanceof String) {
    amount = parseInt(amount, 10);
  }
  if (supportsNumberFormat()) {
    const options = {
      style: 'currency',
      currency: currencySymbol,
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    };
    return amount.toLocaleString(undefined, options);
  }
  let sym = currencySymbol;
  if (currencySymbolMap[currencySymbol]) {
    sym = currencySymbolMap[currencySymbol];
  }
  return `${sym}${amount}`;
}

export function formatCurrencyWithDiscount(amount, symbol, discount) {
  if ((typeof amount === 'undefined') || !symbol || (amount === null)) {
    return 'None';
  }
  let newAmount = amount;
  if (typeof newAmount === 'string' || newAmount instanceof String) {
    newAmount = parseInt(newAmount, 10);
  }
  if (discount) {
    const discountPer = discount / 100;
    newAmount -= (newAmount * discountPer);
  }

  return formatCurrency(newAmount, symbol);
}

export function replaceCurrencyOneUnit(message, symbol) {
  const currencyMsg = formatCurrency(1, symbol);
  let msg = message;
  console.log('x', msg, currencyMsg, msg.replace('{currency1}', currencyMsg));
  msg = msg.replace('{currency1}', currencyMsg);
  return msg;
}

export function formatBlogPublicationDate(dateVal) {
  const months = [
    'Jan',
    'Feb',
    'Ma',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const dateObj = new Date(dateVal);
  const [year, date, monthName] = [
    dateObj.getFullYear(),
    dateObj.getDate(),
    months[dateObj.getMonth()],
  ];
  return `${monthName}. ${date}, ${year}`;
}
