import axios from 'axios';
import Config from '@/config';
import { Enum } from '@/core/utils/enum';
import { BaseType } from '@/store/modules/base';

let apiInstance = null;

export const RequestStateStatus = new Enum({
  NotInit: 'Not Initiated',
  Loading: 'Loading',
  Loaded: 'Loaded',
  Error: 'Error',
  Cancelled: 'Cancelled',
});

export class RequestState extends BaseType {
  constructor(status) {
    super();
    this.status = status || RequestStateStatus.NotInit;
    this.ref = null;
    this.msg = null;
  }

  static typeName() {
    return 'RequestState';
  }
}

export const API = {
  forApikeyHeaderKey: 'rz-api-key',
  forTokenkeyHeaderKey: 'RZ-ACCESS-TOKEN',
  token: null,
  faKey: null,

  createInstance(headers) {
    let defaultHeaders = {};

    if (!Config.isClientEnv) {
      defaultHeaders = { ...defaultHeaders, ...headers };
    }
    // defaultHeaders[API.forApikeyHeaderKey] = Config.rzApiKey;

    const instance = axios.create({
      baseURL: Config.rzApiPath,
      headers: defaultHeaders,
    });

    instance.interceptors.request.use((_config) => {
      console.log(`APIRequestBegin\t${_config.url}`);
      const config = _config;
      config.params = config.params || {};
      config.params[API.forApikeyHeaderKey] = Config.rzApiKey;

      config.meta = config.meta || {};
      config.meta.requestStartedAt = new Date().getTime();
      return config;
    });

    instance.interceptors.response.use((config) => {
      const ms = new Date().getTime() - config.config.meta.requestStartedAt;
      console.log(`APIRequestEnd\t${config.config.url}\t${ms}ms`);
      return config;
    }, (config) => {
      const ms = new Date().getTime() - config.config.meta.requestStartedAt;
      console.error(`APIRequestFAILED\t${config.config.url}\t${ms}ms`);
    });

    console.log('Created new API Instance', defaultHeaders);

    return instance;
  },

  instance() {
    if (apiInstance == null) {
      apiInstance = this.createInstance();
    }
    return apiInstance;
  },

  prepareAPI() {
  },
};
