/* eslint-disable import/prefer-default-export */

import Vue from 'vue';
import VueRouter from 'vue-router';

import { API } from '@/api/core';
import Config from '@/config';

Vue.use(VueRouter);

const routeGuard = (to, from, next, bootInstance, store, reject) => {
  const activeUserKey = bootInstance && bootInstance.user && bootInstance.user.user;
  const userRequired = to.matched.some((record) => record.meta.userRequired);
  const loginRequired = userRequired && (!activeUserKey);
  let nextHandled = false;

  if (loginRequired) {
    console.log('Login Required', from.fullPath, to.fullPath, loginRequired, API.token);
    nextHandled = true;
    const useRegister = to.matched.some((record) => record.meta.useRegister);
    const nextPath = encodeURI(to.fullPath);
    const params = { name: (useRegister) ? 'signUp' : 'login', query: { next: nextPath } };
    if (Config.isClientEnv) {
      // Route Redirection before mount not working. So
      // doing lazy redirection
      if (!store.state.boot.appMounted) {
        store.commit('boot/updateLoginRequired', { params });
        next();
      } else {
        next(params);
      }
    } else {
      const redirectParam = {
        path: (useRegister) ? '/user/register/' : '/user/login/',
        query: { next: nextPath },
      };
      reject({ code: 302, params: redirectParam });
    }
  }

  if (!nextHandled) {
    next();
  }
};

export const createRouter = (apiInstance, apicontext, reject, store) => {
  const routes = [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/Base.vue'),

      children: [
        {
          path: '',
          name: 'Home',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/HomePage.vue'),
          meta: { routeValue: 'home' },
        },
        {
          path: '/old-homepage',
          name: 'Home',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/OldHomePage.vue'),
          meta: { routeValue: 'home' },
        },
        {
          path: 'sports-consulting/',
          name: 'SportsConsulting',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/ConsultingPages.vue'),
          meta: { routeValue: 'sports-consulting' },
        },
        {
          path: 'it-consulting/',
          name: 'ITConsulting',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/ConsultingPages.vue'),
          meta: { routeValue: 'it-consulting' },
        },
        {
          path: 'ecommerce-consulting/',
          name: 'EcommerceConsulting',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/ConsultingPages.vue'),
          meta: { routeValue: 'ecommerce-consulting' },
        },
        {
          path: 'about-us/',
          name: 'AboutUs',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/AboutUs.vue'),
          meta: { routeValue: 'about-us' },
        },
        {
          path: 'contact-us/',
          name: 'ContactUs',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/ContactUs.vue'),
          meta: { routeValue: 'contact-us' },
        },
        {
          path: 'jobs-and-culture/',
          name: 'JobsAndCulture',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/JobsAndCulture.vue'),
          meta: { routeValue: 'careers' },
        },
        {
          path: 'jobs-and-culturev2',
          name: 'JobsAndCulture',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/JobsAndCultureV2.vue'),
          meta: { routeValue: 'careers' },
        },
        {
          path: 'newsroom/archives/',
          name: 'rsBlogArchives',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/BlogArchives.vue'),
          meta: { routeValue: 'archives' },
        },
        {
          path: 'newsroom/:blogid/',
          name: 'rsBlogDetailView',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/BlogDetailView.vue'),
          props: (route) => ({ blogId: route.params.blogid }),
        },
        {
          path: 'newsroom/',
          name: 'rsBlogList',
          component: () => import(/* webpackChunkName: "rzWebsite" */ '../views/BlogList.vue'),
          meta: { routeValue: 'bloglist' },
        },
      ],
    },
    {
      path: '/user/',
      name: 'userViews',
      component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Base.vue'),
      children: [
        {
          path: 'login/',
          name: 'login',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/LoginPage.vue'),
          meta: { routeValue: 'login' },
        },
        {
          path: 'login-response/',
          name: 'LoginResponse',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/LoginResponse.vue'),
        },
        {
          path: 'register/',
          name: 'signUp',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Signup.vue'),
          meta: { routeValue: 'signup' },
        },
        {
          path: 'links/',
          name: 'userLinks',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Links.vue'),
        },
        {
          path: 'me/',
          name: 'userMe',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/Me.vue'),
          meta: { userRequired: true },
        },
        {
          path: 'payments/',
          name: 'userTestPayments',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/TestPayments.vue'),
          meta: { userRequired: true },
        },
        {
          path: 'forgot-password/',
          name: 'forgotPassword',
          component: () => import(/* webpackChunkName: "v5ob" */ '../views/user/ForgotPassword.vue'),
        },
      ],
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_PATH,
    routes,
    scrollBehavior(to) {
      if (to.hash) {
        return {
          selector: to.hash,
        };
      }

      return { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    const bootInstance = (store.state.boot && store.state.boot.instance);
    if (!bootInstance) {
      store.dispatch('boot/getInstance').then(() => {
        routeGuard(to, from, next, store.state.boot.instance, store, reject);
      }, (error) => {
        console.log('Error loading boot loader', error);
      });
    } else {
      routeGuard(to, from, next, bootInstance, store, reject);
    }
  });

  router.afterEach((to) => {
    let gtDataLayer = [];
    if (Config.isClientEnv) {
      gtDataLayer = window.dataLayer;
    }

    const trackConfig = to.meta.tracking || {
      qsDefaults: {},
    };

    const title = trackConfig.title || null;
    let toPath = trackConfig.path || to.path;
    let publicPath = Config.rzPublicPath;
    if (toPath[toPath.length - 1] !== '/') {
      toPath = `${toPath}/`;
    }
    if (publicPath[publicPath.length - 1] === '/') {
      publicPath = publicPath.replace(/\/$/, '');
    }
    toPath = `${publicPath}${toPath}`;
    if (trackConfig.appendQS) {
      const suffix = trackConfig.appendQS.map((q) => `${q}/${to.query[q] || trackConfig.qsDefaults[q] || '-'}`);
      if (suffix.length) {
        toPath = `${toPath}${suffix.join('/')}/`;
      }
    }

    gtDataLayer.push({
      event: 'rz.pageview',
      rzpage: { path: toPath, title },
    });
  });

  return router;
};
