/* eslint-disable import/prefer-default-export */

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import boot from './modules/boot';
import link from './modules/link';
import page from './modules/page';
import user from './modules/user';
import blog from './modules/blog';
import registerCommonTypes from './commonTypes';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
// const enableLoggger = debug;
const enableLoggger = false;

const plugins = [];

if (enableLoggger) {
  plugins.push(...[createLogger()]);
}

registerCommonTypes();

const initState = () => ({
  isDailogActive: false,
  isScrollUp: false,
  isScreenAtTop: true,
  overlayMasterMenu: false,
  daiogIsActive: false,
  apiInstance: null,
  inMemoryCache: null,
});

const mutations = {
  updateDailogActive(state, { active }) {
    Vue.set(state, 'isDailogActive', active);
  },
  updateScrollUp(state, { isUp }) {
    Vue.set(state, 'isScrollUp', isUp);
  },
  updateScreenAtTop(state, { isAtTop }) {
    Vue.set(state, 'isScreenAtTop', isAtTop);
  },
  updateMasterMenuVisiblity(state, { hide }) {
    Vue.set(state, 'overlayMasterMenu', hide);
  },
  daiogIsActive(state, { hide }) {
    Vue.set(state, 'daiogIsActive', hide);
  },

  updateApiInstance(state, { apiInstance }) {
    Vue.set(state, 'apiInstance', apiInstance);
  },

  updateInMemoryCache(state, { inMemoryCache }) {
    Vue.set(state, 'inMemoryCache', inMemoryCache);
  },

  removeSsrContext(state) {
    Vue.set(state, 'apiInstance', null);
    Vue.set(state, 'inMemoryCache', null);
  },

};

export const createStore = () => {
  const store = new Vuex.Store({
    state: initState,
    getters: {
    },
    mutations,
    actions: {
    },
    modules: {
      boot,
      link,
      page,
      user,
      blog,
    },
    strict: debug,
    plugins,
  });

  return store;
};
