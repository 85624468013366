import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';

import Config from '@/config';
import { Enum } from '@/core/utils/enum';
import { HydrationHelper } from '@/store/plugins/hydration';
import CookieManager from '@/core/utils/cookieManager';
import { BaseType } from './base';

export const UserSessionState = new Enum({
  NotVerified: 'Not Verified',
  NotLoggedIn: 'Not Logged In',
  Verifying: 'Verifying',
  LoggedIn: 'Logged In',
  Error: 'Error',
});

export function buildConsoleLink(state, url) {
  const link = `${Config.consoleHost}${url}`;
  // const { user } = state.boot.instance;

  // if (user.loginCode) {
  //   const loginLink = Config.rzConsoleLoginPath;
  //   if (!loginLink) {
  //     console.error('Console Login Path missing');
  //   }

  //   const code = encodeURIComponent(user.loginCode);
  //   const nextUrl = encodeURIComponent(link);
  //   link = `${loginLink}?code=${code}&next_url=${nextUrl}`;
  // }

  return link;
}

export function buildConsoleProjectLink(state, projectKey, url) {
  let link = '';

  if (projectKey) {
    link = `project/${projectKey}/`;
  }

  if (url) {
    link = `${link}${url}`;
  }

  return buildConsoleLink(state, link);
}

export class UserState extends BaseType {
  constructor(status) {
    super();
    this.status = status || UserSessionState.NotVerified;
    this.ref = null;
    this.msg = null;
  }

  static typeName() {
    return 'UserState';
  }
}

export class User extends BaseType {
  constructor() {
    super();
    this.name = null;
    this.email = null;
    this.password = null;
    this.company_name = null;
    this.loggedIn = false;

    this.loginCode = null;
  }

  syncLocalUserData() {
    if (!Config.isClientEnv) {
      return;
    }

    const storeKey = 'rzuser';
    const store = window.localStorage;
    let localData = JSON.parse(store.getItem(storeKey) || '{}');

    // Found different user
    if (localData.userKey && (localData.userKey !== this.user)) {
      localData = {};
      store.removeItem(storeKey);
    }

    const newData = {
      userKey: this.user,
      loginCode: localData.loginCode || null,
      loginCodeExpires: localData.loginCodeExpires || 0,
    };

    // Found new Login Code
    if (this.loginCode) {
      const expiresTime = new Date();
      expiresTime.setTime(expiresTime.getTime() + (60 * 60 * 24 * 1000));

      newData.loginCode = this.loginCode;
      newData.loginCodeExpires = expiresTime.getTime();
    } else if (localData.loginCode) {
      // Use old login code
      if (localData.loginCodeExpires > (new Date()).getTime()) {
        this.loginCode = localData.loginCode;
      } else {
        newData.loginCode = null;
        newData.loginCodeExpires = 0;
      }
    }

    // console.log('Storing new user data', newData, localData, this);
    store.setItem(storeKey, JSON.stringify(newData));
  }

  static buildURL(base, params) {
    const q = Object.keys(params).map((key) => [key, params[key]].map(encodeURIComponent).join('=')).join('&');
    return `${base}?${q}`;
  }

  static createFromJson(raw) {
    let data = new User();
    if (raw) {
      data = Object.assign(data, raw);
      if (raw.user || raw.key) {
        data.loggedIn = true;
      }
    }
    return data;
  }

  static createUser(apiInstance, data) {
    return apiInstance
      .post('/business/user/signup/create_user/', data)
      .then((resp) => this.processLoginResponse(resp));
  }

  static signOut(apiInstance) {
    return apiInstance
      .get('/business/user/logout/')
      .then((resp) => resp)
      .catch((err) => {
        console.error('sign out error', err);
      });
  }

  static fetch() {
    return axios
      .get(`${Config.cricketHost}webauth/me`)
      .then((resp) => {
        return User.createFromJson(resp.data.user);
      }).catch((err) => {
        console.log('err', err);
      });
  }

  static processLoginResponse(resp) {
    const raw = resp.data.data;
    const user = raw.user ? User.createFromJson(raw.user) : null;
    const error = raw.error || null;

    if (user && raw.login_code) {
      user.loginCode = raw.login_code;
    }
    return { user, error };
  }

  static login(apiInstance, data) {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    return apiInstance
      .post('/business/user/login/', formData)
      .then((resp) => this.processLoginResponse(resp));
  }

  static forgotPassword(apiInstance, formData) {
    return apiInstance
      .post('/business/user/forgot_password/', formData)
      .then((resp) => resp).catch((err) => {
        console.log('err', err);
      });
  }

  static resetPassword(apiInstance, resetCode, newPassword) {
    const formData = new FormData();
    formData.append('password', newPassword);
    return apiInstance
      .post(`/business/user/reset-password/${resetCode}/`, formData)
      .then((resp) => resp).catch((err) => {
        console.log('err', err);
      });
  }

  static fetchRestToken(apiInstance) {
    return apiInstance
      .post('/system/auth-user/', {})
      .then((resp) => {
        return resp.data;
      }).catch((err) => {
        console.log('err', err);
      });
  }

  static fetchProfile(apiInstance) {
    return apiInstance
      .get('/business/user_info/')
      .then((resp) => {
        return User.createFromJson(resp.data.data);
      }).catch((err) => {
        console.log('err', err);
      });
  }

  static typeName() {
    return 'User';
  }
}

HydrationHelper.registerType(User);
HydrationHelper.registerType(UserState);

let defaultCurrency = 'inr';
if (Config.isClientEnv) {
  defaultCurrency = CookieManager.getItem('rzCurrency') || defaultCurrency;
}

const initState = () => ({
  activeUser: new User(),
  accessToken: null,
  activeCurrency: defaultCurrency,
  activeUserState: new UserState(),
});

const getters = {
  accessToken: (state) => {
    if (state.accessToken) {
      return state.accessToken;
    // eslint-disable-next-line no-else-return
    } else if (Config.isClientEnv) {
      return CookieManager.getItem('rzAccessToken') || null;
    } else {
      return this.$ssrContext.token;
    }
  },
};

const actions = {
  fetchActiveUser({ commit }) {
    return new Promise((resolve, reject) => {
      commit('updateActiveUserStatus', { status: UserSessionState.Verifying });

      User.fetch().then((data) => {
        if (data) {
          commit('updateActiveUser', { data });
          commit('updateActiveUserStatus', { status: UserSessionState.LoggedIn });
        } else {
          commit('updateActiveUserStatus', { status: UserSessionState.NotLoggedIn });
        }
        resolve(data);
      }).catch((err) => {
        console.log('Error while fetching user ', err);
        commit('updateActiveUserStatus', { status: UserSessionState.Error, msg: err });
        reject(err);
      });
    });
  },

  fetchUser({ commit, rootState }) {
    commit('updateActiveCurrency');
    return new Promise((resolve, reject) => {
      commit('updateActiveUserStatus', { status: UserSessionState.Verifying });

      User.fetchProfile(rootState.apiInstance).then((data) => {
        if (data) {
          commit('updateActiveUser', { data });
          commit('updateActiveUserStatus', { status: UserSessionState.LoggedIn });
        } else {
          commit('updateActiveUserStatus', { status: UserSessionState.NotLoggedIn });
        }
        resolve(data);
      }).catch((err) => {
        console.log('Error while fetching user ', err);
        commit('updateActiveUserStatus', { status: UserSessionState.Error, msg: err });
        reject(err);
      });
    });
  },
};

const mutations = {
  updateAccessToken(state, { token }) {
    if (token && token !== '') {
      Vue.set(state, 'accessToken', token);
    }
  },

  updateActiveCurrency(state, _currency) {
    let currency = 'inr';
    if (_currency && _currency !== '') {
      currency = _currency;
      if (Config.isClientEnv) {
        const expires = moment().add(365, 'day');
        CookieManager.setItem('rzCurrency', currency, expires.toDate(), '/');
      }
    } else if (Config.isClientEnv) {
      currency = CookieManager.getItem('rzCurrency') || 'inr';
    }
    Vue.set(state, 'activeCurrency', currency);
  },

  updateActiveUser(state, { data }) {
    Vue.set(state, 'activeUser', data);
  },

  updateActiveUserStatus(state, { status, msg }) {
    state.activeUserState.status = status;
    state.activeUserState.ref = msg;
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
