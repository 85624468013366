<template>
  <div class="page-request-state" v-if="(!state.status.isLoaded) && (!state.status.isNotInit)">
    <div v-if="state.status.isLoading && (($route.name !== 'signUp')
    && ($route.name !== 'login'))" class="loading-msg">
      loading..
    </div>
    <div v-else-if="state.status.isLoading &&
    (($route.name === 'signUp') || ($route.name === 'login'))" class="loading-msg">
      <svg class="loader"><use v-bind="{'xlink:href':'#form-loader'}"></use></svg>
    </div>
    <error-state v-else-if="state.status.isError" :state="state"></error-state>
    <div v-else>State: {{state}}</div>
</div>
</template>
<style lang="scss" scoped>
  .page-request-state{
    .loading-msg {
      text-align: center;
      img {
        margin-right: 10px;
      }
    }
    .loader {
      display: inline-block;
      width: size(30);
      height: size(30);
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
</style>
<script>
import ErrorState from '@/components/ErrorState.vue';

export default {
  name: 'RequestState',
  props: {
    state: Object,
  },
  components: {
    'error-state': ErrorState,
  },
};
</script>
