<template>
  <div id="app">
    <router-view v-if="!loginRequired" />
    <div v-else>
      <OnBoardingLoader
        :title="'Verifying User'"
        :description="'Give us a minute, as we are processing your request.'"
      />
    </div>
  </div>
</template>
<script>
import smoothscroll from 'smoothscroll-polyfill';

import '@/assets/styles/global/styles.scss';
import { mapState } from 'vuex';
import Config from '@/config';
import OnBoardingLoader from '@/components/Onboarding/OnBoardingLoader.vue';

export default {
  components: {
    OnBoardingLoader,
  },
  computed: {
    ...mapState({
      isScrollUp: (state) => state.isScrollUp,
      isScreenAtTop: (state) => state.isScreenAtTop,

      loginRequired: (state) => state.boot.loginRequired,
      loginParams: (state) => state.boot.loginParams,
      appCreated: (state) => state.boot.appCreated,
      appMounted: (state) => state.boot.appMounted,
      appDataReady: (state) => state.boot.appDataReady,

      daiogActiveState: (state) => state.daiogIsActive,
    }),
  },
  serverPrefetch() {
    this.initListeners();
    this.initApp();
    return this.fetchData();
  },
  mounted() {
    smoothscroll.polyfill();

    this.initListeners();

    if (!this.appCreated) {
      this.initApp();
    }

    if (!this.appDataReady) {
      this.freshFetch();
    } else {
      // This case comes when, comp reloaded
      // but data already comes from Vuex
      this.fetchClientSide();
    }

    this.setupClientSide();
  },
  watch: {
    $route() {
      this.appRouteChange();
    },
  },
  methods: {
    getScrollYPx() {
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      return scrollY;
    },

    getScrollY() {
      const px = this.getScrollYPx();
      return (px) ? parseFloat(px.replace('px', '')) : 0;
    },

    setupClientSide() {
      window.addEventListener('scroll', () => {
        const y = window.scrollY;
        const isScrollUp = 0;
        const isScreenAtTop = y < 120;
        const overlayMasterMenu = (!isScrollUp) && (!isScreenAtTop);

        // if (isScrollUp !== this.isScrollUp) {
        //   console.log('Changing Scrollup', isScrollUp);
        //   this.$store.commit('updateScrollUp', { isUp: isScrollUp });
        // }

        // if (isScreenAtTop !== this.isScreenAtTop) {
        //   console.log('Changing isScreenAtTop', isScreenAtTop);
        //   this.$store.commit('updateScreenAtTop', { isAtTop: isScreenAtTop });
        // }

        if (overlayMasterMenu !== this.overlayMasterMenu && !this.daiogActiveState) {
          this.$store.commit('updateMasterMenuVisiblity', { hide: overlayMasterMenu });

          // to triggering animation multiple times in short time
          // setTimeout(() => {
          //   if (overlayMasterMenu !== this.overlayMasterMenu) {
          //     this.$store.commit('updateMasterMenuVisiblity', { hide: overlayMasterMenu });
          //   }
          // }, 500);
        }

        document.documentElement.style.setProperty('--scroll-y', `${y}px`);
      });

      if (!this.appMounted) {
        this.$store.commit('boot/syncUserData');
        // Route Redirection before mount not working. So
        // doing lazy redirection
        if (this.loginRequired) {
          const params = { ...this.loginParams.params };
          this.$router.push(params, () => {
            this.$store.commit('boot/revokeLoginRequired');
            this.$store.commit('boot/markAppMounted');
          });
        } else {
          this.$store.commit('boot/markAppMounted');
        }
      }

      this.$store.commit('user/updateActiveCurrency');
    },

    prepareDailog() {
      const scrollY = this.getScrollYPx();
      const { body } = document;
      body.style.position = 'fixed';
      body.style.left = 0;
      body.style.right = 0;
      body.style.top = `-${scrollY}`;
      this.$store.commit('daiogIsActive', { hide: true });
    },

    destroyDailog() {
      const { body } = document;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      body.style.left = '';
      body.style.right = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      this.$store.commit('daiogIsActive', { hide: false });
    },

    initListeners() {
      this.$store.subscribe(({ type }, state) => {
        if (type === 'page/updateActivePage') {
          const metaData = state.page.activePage.metaData();
          metaData.inject(this);

          if (Config.isClientEnv) {
            document.title = metaData.title;
          } else {
            this.$ssrContext.title = metaData.title;
          }
        }

        if (type === 'updateDailogActive') {
          if (state.isDailogActive) {
            this.prepareDailog();
          } else {
            this.destroyDailog();
          }
        }
      });
    },

    initApp() {
      this.$store.commit('boot/markAppCreated');
    },

    fetchData() {
      return this.$store.dispatch('link/fetchCricketMaster').then(() => {
        return this.$store.dispatch('link/fetchCricketFooter');
      }).then(() => {
        return this.$store.commit('boot/markAppDataReady');
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
        // console.error('Error on Master Data', err);
      });
    },

    appRouteChange() {
      this.$store.commit('link/updateSeniorMenuState',
        {
          beginCollapse: false,
          expanded: false,
          hide: false,
        });
      this.destroyDailog();
      this.fetchClientSide();
    },

    freshFetch() {
      return this.fetchData().then(() => {
        this.fetchClientSide();
      });
    },

    fetchClientSide() {
      // return this.$store.dispatch('user/fetchActiveUser');
    },

  },
};
</script>
